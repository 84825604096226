html,
body {
  max-width: 100% !important;
  overflow-x: hidden !important;
}

span {
  font-family: Poppins, sans-serif;
  font-weight: 300;
}
p {
  font-family: Poppins, sans-serif;
  font-weight: 300;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.2);
  height: 1px;
}

.numeric-input {
  border: none !important;
  outline: none !important;
  height: 1.7rem;
  text-align: start;
  line-height: 1.7rem;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  text-align: left;
  background-color: rgba(0, 0, 0, 0);
}
.numeric-input-money::placeholder {
  color: #6dd400;
  border: none !important;
  text-decoration: underline;
  outline: none !important;
}
.numeric-input-meditated::placeholder {
  color: black;
  border: none !important;
  text-decoration: underline;
  outline: none !important;
}

.icon-walkthrough-modal {
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
  min-width: 30px;
}

.todaysdate {
  color: #6dd400;
  font-weight: 700;
  position: relative;
}

.active-intro-modal-button:focus {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  border: unset !important;
  background-color: rgba(63, 81, 181, 0.08);
  outline: none;
  font-weight: 700;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(96.1%, 0%, 34.1%, 20%);
    border-radius: 50%;
  }
  100% {
    box-shadow: 0 0 0 35px rgba(0, 0, 0, 0);
    border-radius: 50%;
  }
}

.floating-star {
  position: absolute;
  top: calc(50% - 1rem);
  right: -1rem;
  color: #f50057;
}

/* FONT HELPERS */

/* Weights */
/* #region */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
/* #endregion */

/* Sizes */
.fs-xxs {
  font-size: 0.75rem;
}

.fs-xs {
  font-size: 1rem;
}

.fs-sm {
  font-size: 1.25rem;
}

.fs-md {
  font-size: 1.5rem;
}

.fs-lg {
  font-size: 1.75rem;
}

.fs-xl {
  font-size: 2rem;
}

.fs-xxl {
  font-size: 2.5rem;
}

.addToHomeScreenBanner {
  background: rgb(242, 248, 255);
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  padding: 1rem;
  font-size: 1rem;
  position: fixed;
  bottom: 0px;
}

.plus-icon {
  font-size: 2rem;
  color: gray;
}

.apple-share {
  color: rgb(15, 127, 254);
  transform: rotate(270deg);
  font-weight: 500;
}
